<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <!--
          <div class="add-task">
            <b-button
              variant="primary"
              block
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              添加任务
            </b-button>
          </div>
          -->
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1" v-if="name==='apps-todo-send'">
                下发任务
              </h6>
              <h6 class="section-label mb-1" v-if="name==='apps-todo'">
                待办任务
              </h6>
            </div>
            <b-list-group class="list-group-filters">
              <b-list-group-item
                  v-for="filter in getCodeOptions('todo_status')"
                  :key="filter.value"
                  :to="{name: name, query: {status: filter.value}}"
                  :active="activeStatus===filter.value"
                  @click="$emit('close-left-sidebar');changeStatusActive(filter)"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.label }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                优先级
              </h6>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="priority in getCodeOptions('priority')"
                :key="priority.value"
                :to="{name: 'apps-todo', query: {status:activeStatus,priority:priority.value}}"
                @click="$emit('close-left-sidebar',priority.value)"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${priority.color}`"
                />
                <span>{{ priority.label }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive, isRouteQueryActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import router from "@/router";
import { toTime, toDate, upperCase, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import store from "@/store";
import {ref} from "@vue/composition-api";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  data() {
    return {
      activeStatus: 1,
      name:"",
    }
  },
  props: {
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const changeStatusActive = function(filter) {
      this.activeStatus = filter.value
    }

    return {
      perfectScrollbarSettings,
      changeStatusActive,

      isDynamicRouteActive,
      isRouteQueryActive,

      toDate,
      //UI
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
    }
  },
  created() {
    this.activeStatus = this.$route.query.status || 1
    this.name = this.$route.query.state===1?"apps-todo-send":"apps-todo"
  },
}
</script>

<style>

</style>
