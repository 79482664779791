<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ getCodeLabel('todo_status', taskLocal.status) }}
          </h5>
          <div>
<!--            <feather-icon-->
<!--              v-show="taskLocal.id"-->
<!--              icon="TrashIcon"-->
<!--              class="cursor-pointer"-->
<!--              @click="$emit('remove-task'); hide();"-->
<!--            />-->
<!--            <feather-icon-->
<!--              class="ml-1 cursor-pointer"-->
<!--              icon="StarIcon"-->
<!--              size="16"-->
<!--              :class="{ 'text-warning': taskLocal.priority == 9 }"-->
<!--              @click="taskLocal.priority = 9"-->
<!--            />-->
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <!-- Form -->
        <b-form
            class="p-2"
        >

          <!-- Title -->
          <b-form-group
              label="标题"
          >
            #{{ taskLocal.id }} [{{taskLocal.type}}] {{ taskLocal.title }}
          </b-form-group>

          <!-- Assignee -->
          <b-form-group
              label="被分配人"
          >
            {{ getCodeLabel('user', taskLocal.assignee_id) }}
          </b-form-group>

          <!-- due Date -->
          <b-form-group
              label="到期时间"
              v-if="taskLocal.due_time != null"
          >
            {{ toTime(taskLocal.due_time)}}
          </b-form-group>

          <!-- Description -->
          <b-form-group
              label="任务内容"
          >
            <div v-html="taskLocal.content"></div>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <router-link :to="{path: taskLocal.url}" target="_blank">
            <b-button
                variant="primary"
                class="mr-2"
                type="button"
                v-if="state!==1&&(taskLocal.status===2||taskLocal.status===3)"
            >
              前往处理
            </b-button>
            </router-link>
            <b-button
                variant="success"
                class="mr-2"
                type="button"
                @click="changeStatus(taskLocal,5);hide()"
                v-if="(taskLocal.status===2 || taskLocal.status===3)&&state!==1"
            >
              处理完成
            </b-button>
            <b-button
                variant="danger"
                class="mr-2"
                type="button"
                @click="changeStatus(taskLocal,4);hide()"
                v-if="(taskLocal.status===2 || taskLocal.status===3)&&state!==1"
            >
              拒绝处理
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required, email, url } from '@validations'
import { avatarText, toTime, toDate, upperCase, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import {onUnmounted, toRefs} from '@vue/composition-api'
import useTaskHandler from './useTaskHandler'
import store from "@/store";
import todoStore from "@/views/apps/todo/todoStore";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      state:0,
    }
  },
  setup(props, { emit }) {
    // Register module
    if (!store.hasModule('todo')) store.registerModule('todo', todoStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('todo')) store.unregisterModule('todo')
    })
    const {
      taskLocal,

      // UI
      onSubmit,
    } = useTaskHandler(toRefs(props), emit)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your content',
    }

    const priority = getCode('priority', props.task.priority)

    const changeStatus = function (params,status) {
      store.dispatch('todo/changeStatus', { todoid: params.todo_id, status: status,username:getCodeLabel('user', params.assignee_id) })
          .then(() => {
            this.$emit('flush')
          })
    }

    return {
      // Add New
      taskLocal,
      onSubmit,
      priority,

      // Filters
      avatarText,
      toDate,
      toTime,

      // UI
      editorOption,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      changeStatus,

    }
  },
  created() {
    this.state = this.$route.params.state
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
