<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowSidebarLeft}"
      @click="mqShallShowSidebarLeft = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowSidebarLeft = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="搜索待办任务 "
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>
      </div>


      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="tasks"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="task in tasks"
            :key="task.id"
            class="todo-item"
            :class="{ 'completed': task.status==5} "
            :variant="`lighten-${getCodeColor('priority', task.priority)}`"
            @click="handleTaskClick(task)"
          >
            <!--
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            -->
            <div class="todo-title-wrapper" @click="changeStatus(task,3)">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    :checked="task.isCompleted"
                    @click.native.stop
                    @change="updateTaskIsCompleted(task)"
                  />
                  <span class="todo-title" v-if="task.url!==''||task.url!==undefined">#{{ task.id }} [{{task.type}}] {{ task.title }}</span>
                  <span class="todo-title" v-if="task.url===''||task.url===undefined">{{ task.content }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <small class="text-nowrap text-muted mr-1">{{ toTime(task.due_time) }}</small>
                <b-avatar
                  size="32"
                  :src="getCodeIcon('user', task.assignee_id)"
                  :variant="`light-${getCodeColor('priority', task.priority)}`"
                  :text="avatarText(getCodeLabel('user', task.assignee_id))"
                />
              </div>
            </div>

          </li>
        </draggable>
        <div
          class="no-results"
          :class="{'show': !tasks.length}"
        >
          <h5>未找到记录</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      @remove-task="removeTask"
      @add-task="saveTask"
      @update-task="saveTask"
      @flush="fetchTasks"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-sidebar-left
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowSidebarLeft}"
        @close-left-sidebar="formChild"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BBadge, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { avatarText, toTime, toDate, upperCase, getCodeOptions, getCode, getCodeLabel, getCodeColor, getCodeIcon } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TodoSidebarLeft from './TodoSidebarLeft.vue'
import todoStore from './todoStore'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    TodoSidebarLeft,
    TodoTaskHandlerSidebar,
  },
  data(){
    return {
      state:0,
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('todo')) store.registerModule('todo', todoStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('todo')) store.unregisterModule('todo')
    })

    const formChild = function (val) {
      this.mqShallShowSidebarLeft = false;
    }

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)

    const routeParams = computed(() => route.value.query)
    const state = route.value.params.state


    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks()
    })

    const tasks = ref([])

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-time',
    ]
    const sortBy = ref(routeSortBy.value)

    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })

    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    }

    const blankTask = {

    }

    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const removeTask = taskData => {
      store.dispatch('todo/state', { id: taskData.id, state: 0 })
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }
    const saveTask = taskData => {
      store.dispatch('todo/save', { task: taskData })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }


    const isTaskHandlerSidebarActive = ref(false)

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const fetchNotification = () => {
      store.dispatch('todo/ListStatus', {}).then(res => {
        let list = res.data.data
        store.commit('notification/updateLength',list.length)
        list.splice(5)
        store.commit('notification/updateItem',list)
      })
    }

    const fetchTasks = () => {
      if (routeParams.value.status===undefined){
        routeParams.value.status=2


      }
      store
          .dispatch('todo/tasks', {
            q: searchQuery.value,
            status: routeParams.value.status,
            priority: routeParams.value.priority,
            sortBy: sortBy.value,
            states:state,
          })
          .then(response => {
            tasks.value = response.data.data.ext.list
          })
          .catch((e) => {
          })

    }



    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted
      saveTask(taskData)
    }

    const { mqShallShowSidebarLeft } = useResponsiveAppLeftSidebarVisibility()

    const changeStatus = function (params,status) {
      if (params.status===2&&state!==1){
        store.dispatch('todo/changeStatus', { todoid: params.todo_id, status: status })
            .then(() => {
              fetchTasks()

              fetchNotification()

            })
      }if (params.status===2&&state===1&&params.url===undefined) {
        store.dispatch('todo/changeStatus', { todoid: params.todo_id, status: 5 })
            .then(() => {
              fetchTasks()
            })
      }

    }

    return {
      task,
      tasks,
      removeTask,
      saveTask,
      clearTaskData,
      searchQuery,
      fetchTasks,
      fetchNotification,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      avatarText,
      toDate,
      toTime,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      //UI
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      getCodeIcon,
      formChild,
      changeStatus,


      // Left Sidebar Responsive
      mqShallShowSidebarLeft,
    }
  },
  created() {
    this.fetchTasks()
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    /*
    cursor: move;
   */

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
